html {
    overflow-x: none;
}

body {
    height: 100vh;
    width: 100vw;
    background-color: #1c1c1c;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
    overflow-x: hidden;
    -webkit-overflow-scrolling: none;
    overscroll-behavior: none;
    user-select: none;
    color: white;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1D3020;
    --bs-btn-border-color: #1D3020;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #33501c;
    --bs-btn-hover-border-color: #33501c;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #253625;
    --bs-btn-active-border-color: #253625;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #2D4130;
    --bs-btn-disabled-border-color: #2D4130;
}

.asterisk {
    font-weight: bold;
    color: red;
}

.contact-container {
    margin-bottom: 2rem;
}

.contact h3 {
    margin-top: 1rem;
    text-align: left;
}

.contact h4 {
    font-size: 1.25rem;
    text-indent: 1rem;
}

.gpt {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
}

.gpt h1, h2, h3{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.gpt h1 {
    font-size: 4rem;
}

.gpt h4 {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 1.05rem;
}

.gpt iframe {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.gpt-img {
    max-width: 80%;
    max-height: 80%;
    align-self: center;
    border-radius: 16px;
    margin-bottom: 2rem;
}

.quote {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #dddddd;
    font-size: 1.5rem;
    font-style: italic;
    padding-left: 7vw;
    padding-right: 7vw;
}

.quote-attr {
    font-style: normal;
    margin: 0;
    padding: 0;
}

.gpt-content {
    width: 85vw;
    border: 1px solid white;
    border-radius: 16px;
    background-color: #253625;
    color: white;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.gpt-content p{
    padding-left: 7vw;
    padding-right: 7vw;
    text-indent: 2em;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.video-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 1.5rem;
}

.videoWrapper {
    width: 60%;
    margin-bottom: 1rem;
}

.video {
    position: relative;
    /*16:9 ratio*/
    padding-bottom: 56.25%;
    height: 0;
}

.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.content-container {
    display: flex;
    justify-content: center;
    padding-left: 7vw;
    padding-right: 7vw;
    border-radius: 16px;
}

.code {
    background: #3d3d3d;
}

.about {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    padding-top: 20px;
    width: 100vw;
    color: white;
}

.about h1 {
    color: white;
}

.interest-container {
    display: grid;
    width: 90vw;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 2vw;
    margin-bottom: 2rem;
}

.interest {
    background-color: #253625;
    outline: 2px solid white;
    border-radius: 16px;
    box-shadow: -10px 10px 5px rgba(82, 82, 82, 0.45);
}

.interest p {
    background: linear-gradient(to left, #253625 0%, #253625 35%, #ffffff 60%, #ffffff 100%);
    height: 100%;
    width: 100%;
    background-position: 100% 0%;
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 550;
    font-size: 1.15rem;
}

@keyframes activate {
    0% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.interest-active {
    background: linear-gradient(to left, #253625 0%, #253625 35%, #ffffff 60%, #ffffff 100%);
    height: 100%;
    width: 100%;
    background-position: 0% 0%;
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 550;
    animation: activate 3s ease;
    animation-fill-mode: forwards;
}

.project-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 4rem;
}

.project {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    width: 80vw;
    background-color: #253625;
    outline: 2px solid white;
    align-items: center;
    border-radius: 16px;
}

.project-visual {
    display: grid;
    padding: 10px;
    justify-items: center;
    max-width: 100%;
}

.project-image {
    max-height: 500px;
    max-width: 50vw;
}

.project-text {
    padding-left: 2%;
    padding-right: 2%;
}

.repo {
    text-decoration: none;
    color: white;
    background: #33501c;
    border-radius: 9px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    outline: 0.5px solid white;
    border: none;
    cursor: pointer;
}

.site-repo {
    display: flex;
    gap: 10px;
}

.bio, .ycs, .yh{
    background-color: #253625;
    outline: 2px solid white;
    border-radius: 16px;
    width: 100%;
}

.bio p {
    padding-left: 2%;
    padding-right: 2%;
}

.bio-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: fit-content fit-content fit-content;
    grid-gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    width: 90vw;
}

.bio-image-container {
    grid-row-start: 1;
    grid-row-end: 4;
    text-align: center;
    justify-self: center;
    align-self: 40%;
    width: 80%;
    height: 80%;
}

.bio-image {
    width: 100%;
    height: 90%;
    border-radius: 16px;
    object-fit: cover;
    object-position: 55% 0%;
    outline: 1px solid white;
    margin-top: 5rem;
}

@media (min-width: 850px) {
    .resume {
        height: 550px;
        width: 850px;
    }
    .project-pdf {
        height: 550px;
        width: 425px
    }
}

@media (max-width: 850px) {
    .resume {
        height: 550px;
        width: 90vw;
    }
    .project-pdf {
        height: 550px;
        width: 75vw;
    }
}

.mobile-gallery {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    margin-top: 2rem;
    padding-bottom: 2rem;
}

.mobile-image {
    max-width: 80vw;
    object-fit: cover;
}

.header-container {
    display: flex;
    position: center;
    color: white;
    justify-content: center;
    text-align: center;
    margin-top: 2vh;
}

.header-container h1 {
    font-size: 3vmax;
}

#image-track > .image {
    width: 40vmin;
    height: 56vmin;
    object-fit: cover;
    object-position: 100% 50%;
}

#image-track {
    display: flex;
    gap: 4vmin;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(0%, -50%);
}

#fullpage {
    display: none;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-color: black;
}

.about-box, .recommend-box, .gallery-box, .scale-box, .contact-box, .about-box-mobile, .recommend-box-mobile, .gallery-box-mobile, .scale-box-mobile, .contact-box-mobile, .ai-box, .ai-box-mobile {
    height: 30vmin;
    width: 30vmin;
    margin: 5vmin;
    margin-top: 0;
    transform: translate(0%, 30%);
    display: flex;
    border-radius: 16px;
    box-shadow: -15px 15px 10px rgba(82, 82, 82, 0.45);
    transition: height 350ms ease, width 350ms ease, box-shadow 350ms ease;
    margin-bottom: 10vmin;
}

.about-box:hover, .recommend-box:hover, .gallery-box:hover, .scale-box:hover, .contact-box:hover, .ai-box:hover {
    height: 33vmin;
    width: 33vmin;
    box-shadow: -45px 45px 30px rgba(82, 82, 82, 0.45);
}

.about-box-mobile, .recommend-box-mobile, .gallery-box-mobile, .scale-box-mobile, .contact-box-mobile, .ai-box-mobile {
    box-shadow: none;
}

.mobile-container {
    text-align: center;
    width: 100%;
}

.about-box, .about-box-mobile {
    background-image: url("../public/image/grad_walk.jpg");
    background-size: 275%;
    background-position: 50% 50%;
}

.recommend-box, .recommend-box-mobile {
    background-image: url("https://upload.wikimedia.org/wikipedia/en/7/74/The_Last_Stand_of_the_Tin_Can_Sailors.jpg");
    background-size: cover;
}

.gallery-box, .gallery-box-mobile {
    background-image: url('../public/image/gallery/aryasnow.jpg');
    background-size: cover;
    background-position: 25% 0%;
}

.scale-box, .scale-box-mobile {
    background-image: url("../public/image/ninetysecond_infantry.jpg");
    background-size: cover;
}

.contact-box, .contact-box-mobile {
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7PEPmWe92UMQHUMfIF-dWuLP_2cp-7QdteA&usqp=CAU");
    background-size: cover;
}

.ai-box, .ai-box-mobile {
    background-image: url("../public/image/gpt_logo.jpg");
    background-size: cover;
}

.effect {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 16px;
    background: linear-gradient(
        130deg, 
        transparent 0% 33%,  
        rgb(38, 112, 38) 66%, 
        rgb(99, 158, 38) 83.5%, 
        rgb(177, 124, 84) 100%
    );
    background-position: 0% 0%;
    background-size: 300% 300%;
    transition: background-position 350ms ease, transform 350ms ease, color 700ms ease;
    color: #ffffff00;
    
    text-align: center;
    text-shadow: -15px 15px 10px rgba(49, 49, 49, 0);
}

.effect:hover {
    background-position: 100% 100%;
    transform: scale(1.1, 1.1);
    color: #ffffffff;
    text-shadow: -15px 15px 10px rgba(49, 49, 49, 0.45);
}

@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

.scale {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.quiz-container {
    font-family: 'Anek Malayalam', sans-serif;
    background-image: none;
    background-size: cover;
    color: #000000;
    display: flex;
    justify-content: center;
    max-width: 50vw;
    background: #c3c3c3e5;
    border-radius: 16px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 10px 45px;
}

.quiz-container .active-question-no {
    font-size: 32px;
    font-weight: 500;
    color: #df1616;
}

.quiz-container .total-question {
    font-size: 16px;
    font-weight: 500;
    color: #3d3d3d;
}

.quiz-container h1{
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    color: #000000;
    text-align: center;
}

.quiz-container h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    color: #000000;
}

.quiz-container h3 {
    color: #000000;
    text-align: center;
}

.quiz-container p {
    color: #000000;
     margin: -1px 0px;
    font-size: 12px;
}

.quiz-container ul {
    margin-top: 20px;
    margin-left: -40px;
}

.quiz-container ul li {
    text-decoration: none;
    list-style: none;
    color: #000000;
    font-size: 16px;
    background: #f3f3f9;
    background-position: right bottom;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 11px;
    margin-top: 15px;
    cursor: pointer;
}

.quiz-container ul .selected-answer {
    background: linear-gradient(to left, #f3f3f9 50%, #ffdfdf 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    border: 1px solid #9c0000;
    transition: all .5s ease-out;
}

.quiz-container button {
    background: linear-gradient(90.04deg, #800000 0.03%, #ffc0c0 99.96%);
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
}

.quiz-container button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
}

.image-credit {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.cred {
    margin-top: 2rem;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.result h3 {
    font-size: 24px;
    letter-spacing: 1.4px;
    text-align: center;
    color: #000000;
}

.result p {
    font-size: 16px;
    font-weight: 500;
}

.result p span {
    color: #a70303;
    font-size: 22px;
}

@media (min-width: 600px) {

    .home {
        background-size: cover;
        background-attachment: fixed;
        background-color: #1c1c1c;
        justify-content: center;
        margin: 0 auto;
        padding: 0 0px;
        width: 100vw;
      }
    
    .home-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .recommendation_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;
    }

    .recommendation{
        width: 60vw;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: fit-content(10ch) 1fr;
        border: 1px solid white;
        border-radius: 16px;
        background-color: #253625;
        color: white;
    }

    .title_author {
        grid-column-start: 2;
        grid-column-end: 4;
        gap: 0rem;
        padding-right: 2%
    }

    .rec {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
        padding-right: 2%;
    }

    .rec_image_container {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        align-items: center;
        justify-items: center;
    }

    .rec_image {
        max-width: 90%;
        max-height: 90%;
    }

    .title_author h2 {
        font-family: Arial, helvetica, sans-serif;
        margin-bottom: 0px;
    }

    .title_author h3 {
        font-family: Arial, helvetica, sans-serif;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .rec p {
        font-family: Arial, helvetica, sans-serif;
        margin-top: 15px;
    }

    .recommendation_container h1 {
        font-family: Arial, helvetica, sans-serif;
        font-size: 4rem;
        margin-bottom: 5px;
    }
}

@media (max-width: 600px){
    
    .home {
        background-color: #1c1c1c;
        width: 100vw;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: row;
        z-index: 1;
        color: white;
        text-align: center;
        justify-content: center;
      }
    
    .home-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .scale-box p {
        font-size: .8rem;
    }

    .recommendation_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;
    }

    .recommendation{
        width: 60vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: fit-content(10ch) fit-content(20ch) 1fr;
        border: 1px solid white;
        border-radius: 16px;
        background-color: #253625;
        color: white;
        padding: .5rem;
    }

    .title_author {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        gap: 0rem;
    }

    .rec {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .rec_image_container {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        align-items: center;
        justify-items: center;
    }

    .rec_image {
        max-width: 90%;
        max-height: 90%;
    }

    .title_author h2 {
        font-family: Arial, helvetica, sans-serif;
        margin-bottom: 0px;
    }

    .title_author h3 {
        font-family: Arial, helvetica, sans-serif;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .rec p {
        font-family: Arial, helvetica, sans-serif;
        margin-top: 15px;
    }

    .recommendation_container h1 {
        font-family: Arial, helvetica, sans-serif;
        font-size: 4rem;
        margin-bottom: 5px;
    }

    .gpt-content p {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .quiz-container {
        max-width: 80vw;
    }
}

.filler {
    height: 90vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.caret-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.linker {
    text-decoration: none;
    color: #ffffff;
}

.NavbarItems, .FootItems {
    background: linear-gradient(90deg, rgb(29, 48, 32) 0%, rgba(45, 65, 48, 1) 100%);
    opacity: 97%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem
}

.foot {
    width: 100vw;
    margin-top: auto;
}

.FootItems {
    height: 6vh;
    width: 100vw;
    align-self: flex-end;
}

.navbar-logo {
    color: #ffffff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.icon-grey {
    color: #aaaaaa;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu, .foot-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    margin-right: 2rem;
}

.nav-menu {
    justify-content: end;
}

.foot-menu {
    justify-content: center;
    padding-top: 1rem;
    font-size: 1rem;
}

.nav-links, .foot-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover, .foot-links:hover {
    background-color: #33501c;
    border-radius: 8px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #ffffff;
}

.menu-icon {
    display: none;
}

@media (max-width: 1250px) {
    .NavbarItems {
        position: relative;
        width: 100vw;
        z-index: 9999;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #2D4130;
        left: 0;
        opacity: 1;
        transition: all 0.5 ease;
        z-index: 9999;
    }

    .nav-links, .foot-links {
        text-align: center;
        width: 100%;
        display: table;
    }

    .nav-links {
        margin-top: 1.5vh;
        margin-bottom: 1.5vh;
    }

    .nav-links:hover, .foot-links:hover {
        background-color: #2D4130;
        border-radius: 8px;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%)
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #ffffff;
        font-size: 2rem;
    }

    .bio-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: fit-content;
        grid-gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 4rem;
        width: 90vw;
    }
    
    .bio-image-container {
        display: grid;
        justify-self: center;
        align-items: center;
        margin-bottom: 3rem;
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-column-end: 2;
    }

    .interest-container {
        display: grid;
        width: 90vw;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-gap: 2vw;
        margin-bottom: 2rem;
    }

    .interest {
        box-shadow: none;
    }

    .project {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        width: 80vw;
        background-color: #253625;
        outline: 2px solid white;
        align-items: center;
        border-radius: 16px;
    }
    
}